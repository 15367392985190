import { createRouter, createWebHistory } from "vue-router";

import store from "@/stores";
import axios from "axios";

import { useDiagram } from "../stores/data.js";

import HomeView from "../views/HomeView.vue";
import { ip } from "../ip_config";
import Header from "../views/template/HeaderView.vue";
import Sidebar from "../views/template/SidebarView.vue";
import Footer from "../views/template/FooterView.vue";
import { ref, reactive, computed, watch, onMounted } from "vue";

const routes = [
  {
    path: "/home",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/AboutView.vue"),
  },
  {
    path: "/",
    name: "blank",
    beforeEnter: async (to, from, next) => {
      if (localStorage.getItem("user_token")) {
        next();
      } else {
        next("/login");
      }
    },
    components: {
      Header,
      Sidebar,
      Footer,
      default: () => import("../views/BlankView.vue"),
    },
  },
  {
    path: "/login",
    name: "login",
    components: {
      default: () => import("../views/user/LoginView.vue"),
    },
  },
  {
    path: "/lowongan",
    name: "lowongan",
    components: {
      Header,
      Sidebar,
      Footer,
      default: () => import("../views/lowongan/LowonganView.vue"),
    },
  },
  {
    path: "/data-user",
    name: "datauser",
    components: {
      Header,
      Sidebar,
      Footer,
      default: () => import("../views/user/DataUserView.vue"),
    },
  },
  {
    path: "/add-user",
    name: "adduser",
    components: {
      Header,
      Sidebar,
      Footer,
      default: () => import("../views/user/AddUserView.vue"),
    },
  },
  {
    path: "/updateuser/:id",
    name: "update",
    components: {
      Header,
      Sidebar,
      Footer,
      default: () => import("../views/user/UpdateUserView.vue"),
    },
  },
  {
    path: "/addlowongan",
    name: "addlowongan",
    components: {
      Header,
      Sidebar,
      Footer,
      default: () => import("../views/lowongan/AddLowonganView.vue"),
    },
  },
  {
    path: "/editlowongan/:id",
    name: "editlowongan",
    components: {
      Header,
      Sidebar,
      Footer,
      default: () => import("../views/lowongan/EditLowonganView.vue"),
    },
  },
  {
    path: "/masterfile",
    name: "masterfile",
    components: {
      Header,
      Sidebar,
      Footer,
      default: () => import("../views/master/MasterFileView.vue"),
    },
  },
  {
    path: "/masterseleksi",
    name: "masterseleksi",
    components: {
      Header,
      Sidebar,
      Footer,
      default: () => import("../views/master/MasterSeleksiView.vue"),
    }
  },
  {
    path: "/mastertipepekerjaan",
    name: "mastertipepekerjaan",
    components: {
      Header,
      Sidebar,
      Footer,
      default: () => import("../views/master/MasterTipePekerjaanView.vue"),
    }
  },
  {
    path: "/add-masterfile",
    name: "add-masterfile",
    components: {
      Header,
      Sidebar,
      Footer,
      default: () => import("../views/master/AddMasterFileView.vue"),
    },
  },
  {
    path: "/add-admin",
    name: "addadmin",
    components: {
      Header,
      Sidebar,
      Footer,
      default: () => import("../views/AddAdminView.vue"),
    },
  },
  {
    path: "/list-pelamar",
    name: "listPelamar",
    components: {
      Header,
      Sidebar,
      Footer,
      default: () => import("../views/pelamar/ListPelamarView.vue"),
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    components: {
      default: () => import("../views/404View.vue"),
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
 

export default router;
