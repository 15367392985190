<template>
    <!-- ======= Footer ======= -->
    <footer id="footer" class="footer">
        <div class="copyright"> &copy; Copyright <strong>
                <span>Fosan Batch XI</span>
            </strong>. All Rights Reserved </div>
        <div class="credits">
            <!-- All the links in the footer should remain intact. -->
            <!-- You can delete the links only if you purchased the pro version. -->
            <!-- Licensing information: https://bootstrapmade.com/license/ -->
            <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/nice-admin-bootstrap-admin-html-template/ --> Designed by <a>FOSAN BATCH XI</a>
        </div>
    </footer>
    <!-- End Footer -->
</template>