// import { createApp } from 'vue'
// import App from './App.vue'
// import router from './router'
// import store from './store'

// createApp(App).use(store).use(router).mount('#app')

// import './assets/main.css'
//import pinia
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import CanvasJSChart from '@canvasjs/vue-charts';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import {LoadingPlugin} from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
// ES6 Modules or TypeScript
import Swal from 'sweetalert2'

// CommonJS

// import { Bar } from 'vue-chartjs'



const app = createApp(App)
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
app.component('VueDatePicker', VueDatePicker);
app.use(CanvasJSChart)
app.use(LoadingPlugin)
app.use(router)
app.use(pinia)
// app.use(Bar)

app.mount('#app')
