<template>
  <!-- ======= Sidebar ======= -->
  <aside id="sidebar" class="sidebar">
    <ul class="sidebar-nav" id="sidebar-nav">
      <li @click="grafik" class="nav-item" data-bs-parent="#sidebar-nav">
        <a class="nav-link collapsed" data-bs-toggle="collapse" href="#">
          <i class="bi bi-bar-chart-fill"></i>
          <span>DASHBOARD</span>
          <!-- <i class="bi bi-chevron-down ms-auto"></i> -->
        </a>
      </li>

      <li @click="lowongan" class="nav-item">
        <a
          class="nav-link collapsed"
          data-bs-target="#lowongan"
          data-bs-toggle="collapse"
          href="#"
        >
          <i class="fa fa-tasks"></i>
          <span>LOWONGAN</span>
        </a>
      </li>
      <!-- End Dashboard Nav -->
      <li @click="dataUser" class="nav-item">
        <a
          class="nav-link collapsed"
          data-bs-target="#components-nav"
          data-bs-toggle="collapse"
          href="#"
        >
          <i class="bi bi-people-fill"></i>
          <span>USER</span>
          <!-- <i class="bi bi-chevron-down ms-auto"></i> -->
        </a>
      </li>

      <li class="nav-item">
        <a
          class="nav-link collapsed"
          data-bs-target="#master"
          data-bs-toggle="collapse"
          href="#"
        >
          <i class="fa fa-file-text"></i>
          <span>MASTER</span>
          <!-- <i class="bi bi-chevron-down ms-auto"></i> -->
        </a>
      </li>

      <ul
        id="master"
        class="nav-content collapse"
        data-bs-parent="#sidebar-nav"
      >
        <li @click="file" class="nav-item">
          <a href="#">
            <i class="bi bi-circle"></i>
            <span>Master File</span>
          </a>
        </li>
        <li @click="seleksi">
          <a href="#">
            <i class="bi bi-circle"></i>
            <span>Master Seleksi</span>
          </a>
        </li>

        <li @click="tipePekerjaan">
          <a href="#">
            <i class="bi bi-circle"></i>
            <span>Master Tipe Pekerjaan </span>
          </a>
        </li>
      </ul>

      <li @click="listPelamar()" class="nav-item">
        <a
          class="nav-link collapsed"
          data-bs-target="#"
          data-bs-toggle="collapse"
          href="#"
        >
          <i class="bi bi-person-vcard-fill"></i>
          <span>LIST PELAMAR</span>
          <!-- <i class="bi bi-chevron-down ms-auto"></i> -->
        </a>
      </li>

      <li @click="tambahAdmin" class="nav-item">
        <a
          class="nav-link collapsed"
          data-bs-target="#"
          data-bs-toggle="collapse"
          href="#"
        >
          <i class="bi bi-person-fill-add"></i>
          <span>TAMBAH ADMIN</span>
          <!-- <i class="bi bi-chevron-down ms-auto"></i> -->
        </a>
      </li>

      <!-- <li>
                       <a href="components-badges.html">
                            <i class="bi bi-circle"></i>
                            <span>Badges</span>
                        </a>
                    </li>
                    <li>
                        <a href="components-breadcrumbs.html">
                            <i class="bi bi-circle"></i>
                            <span>Breadcrumbs</span>
                        </a>
                    </li>
                    <li>
                        <a href="components-buttons.html">
                            <i class="bi bi-circle"></i>
                            <span>Buttons</span>
                        </a>
                    </li>
                    <li>
                        <a href="components-cards.html">
                            <i class="bi bi-circle"></i>
                            <span>Cards</span>
                        </a>
                    </li>
                    <li>
                        <a href="components-carousel.html">
                            <i class="bi bi-circle"></i>
                            <span>Carousel</span>
                        </a>
                    </li>
                    <li>
                        <a href="components-list-group.html">
                            <i class="bi bi-circle"></i>
                            <span>List group</span>
                        </a>
                    </li>
                    <li>
                        <a href="components-modal.html">
                            <i class="bi bi-circle"></i>
                            <span>Modal</span>
                        </a>
                    </li>
                    <li>
                        <a href="components-tabs.html">
                            <i class="bi bi-circle"></i>
                            <span>Tabs</span>
                        </a>
                    </li>
                    <li>
                        <a href="components-pagination.html">
                            <i class="bi bi-circle"></i>
                            <span>Pagination</span>
                        </a>
                    </li>
                    <li>
                        <a href="components-progress.html">
                            <i class="bi bi-circle"></i>
                            <span>Progress</span>
                        </a>
                    </li>
                    <li>
                        <a href="components-spinners.html">
                            <i class="bi bi-circle"></i>
                            <span>Spinners</span>
                        </a>
                    </li>
                    <li>
                        <a href="components-tooltips.html">
                            <i class="bi bi-circle"></i>
                            <span>Tooltips</span>
                        </a>
                    </li>
                </ul> -->
      <!-- </li> -->
      <!-- End Components Nav -->
      <!-- <li class="nav-item">
                <a class="nav-link collapsed" data-bs-target="#forms-nav" data-bs-toggle="collapse" href="#">
                    <i class="bi bi-journal-text"></i>
                    <span>Forms</span>
                    <i class="bi bi-chevron-down ms-auto"></i>
                </a>
                <ul id="forms-nav" class="nav-content collapse " data-bs-parent="#sidebar-nav">
                    <li>
                        <a href="forms-elements.html">
                            <i class="bi bi-circle"></i>
                            <span>Form Elements</span>
                        </a>
                    </li>
                    <li>
                        <a href="forms-layouts.html">
                            <i class="bi bi-circle"></i>
                            <span>Form Layouts</span>
                        </a>
                    </li>
                    <li>
                        <a href="forms-editors.html">
                            <i class="bi bi-circle"></i>
                            <span>Form Editors</span>
                        </a>
                    </li>
                    <li>
                        <a href="forms-validation.html">
                            <i class="bi bi-circle"></i>
                            <span>Form Validation</span>
                        </a>
                    </li>
                </ul>
            </li> -->
      <!-- End Forms Nav -->
      <!-- <li class="nav-item">
                <a class="nav-link collapsed" data-bs-target="#tables-nav" data-bs-toggle="collapse" href="#">
                    <i class="bi bi-layout-text-window-reverse"></i>
                    <span>Tables</span>
                    <i class="bi bi-chevron-down ms-auto"></i>
                </a>
                <ul id="tables-nav" class="nav-content collapse " data-bs-parent="#sidebar-nav">
                    <li>
                        <a href="tables-general.html">
                            <i class="bi bi-circle"></i>
                            <span>General Tables</span>
                        </a>
                    </li>
                    <li>
                        <a href="tables-data.html">
                            <i class="bi bi-circle"></i>
                            <span>Data Tables</span>
                        </a>
                    </li>
                </ul>
            </li> -->
      <!-- End Tables Nav -->
      <!-- <li class="nav-item">
                <a class="nav-link collapsed" data-bs-target="#charts-nav" data-bs-toggle="collapse" href="#">
                    <i class="bi bi-bar-chart"></i>
                    <span>Charts</span>
                    <i class="bi bi-chevron-down ms-auto"></i>
                </a>
                <ul id="charts-nav" class="nav-content collapse " data-bs-parent="#sidebar-nav">
                    <li>
                        <a href="charts-chartjs.html">
                            <i class="bi bi-circle"></i>
                            <span>Chart.js</span>
                        </a>
                    </li>
                    <li>
                        <a href="charts-apexcharts.html">
                            <i class="bi bi-circle"></i>
                            <span>ApexCharts</span>
                        </a>
                    </li>
                    <li>
                        <a href="charts-echarts.html">
                            <i class="bi bi-circle"></i>
                            <span>ECharts</span>
                        </a>
                    </li>
                </ul>
            </li> -->
      <!-- End Charts Nav -->
      <!-- <li class="nav-item">
                <a class="nav-link collapsed" data-bs-target="#icons-nav" data-bs-toggle="collapse" href="#">
                    <i class="bi bi-gem"></i>
                    <span>Icons</span>
                    <i class="bi bi-chevron-down ms-auto"></i>
                </a>
                <ul id="icons-nav" class="nav-content collapse " data-bs-parent="#sidebar-nav">
                    <li>
                        <a href="icons-bootstrap.html">
                            <i class="bi bi-circle"></i>
                            <span>Bootstrap Icons</span>
                        </a>
                    </li>
                    <li>
                        <a href="icons-remix.html">
                            <i class="bi bi-circle"></i>
                            <span>Remix Icons</span>
                        </a>
                    </li>
                    <li>
                        <a href="icons-boxicons.html">
                            <i class="bi bi-circle"></i>
                            <span>Boxicons</span>
                        </a>
                    </li>
                </ul>
            </li> -->
      <!-- End Icons Nav -->
      <!-- <li class="nav-heading">Pages</li>
            <li class="nav-item">
                <a class="nav-link collapsed" href="users-profile.html">
                    <i class="bi bi-person"></i>
                    <span>Profile</span>
                </a>
            </li> -->
      <!-- End Profile Page Nav -->
      <!-- <li class="nav-item">
                <a class="nav-link collapsed" href="pages-faq.html">
                    <i class="bi bi-question-circle"></i>
                    <span>F.A.Q</span>
                </a>
            </li> -->
      <!-- End F.A.Q Page Nav -->
      <!-- <li class="nav-item">
                <a class="nav-link collapsed" href="pages-contact.html">
                    <i class="bi bi-envelope"></i>
                    <span>Contact</span>
                </a>
            </li> -->
      <!-- End Contact Page Nav -->
      <!-- <li class="nav-item">
                <a class="nav-link collapsed" href="pages-register.html">
                    <i class="bi bi-card-list"></i>
                    <span>Register</span>
                </a>
            </li> -->
      <!-- End Register Page Nav -->
      <!-- <li class="nav-item">
                <a class="nav-link collapsed" href="pages-login.html">
                    <i class="bi bi-box-arrow-in-right"></i>
                    <span>Login</span>
                </a>
            </li> -->
      <!-- End Login Page Nav -->
      <!-- <li class="nav-item">
                <a class="nav-link collapsed" href="pages-error-404.html">
                    <i class="bi bi-dash-circle"></i>
                    <span>Error 404</span>
                </a>
            </li> -->
      <!-- End Error 404 Page Nav -->
      <!-- <li class="nav-item">
                <a class="nav-link " href="pages-blank.html">
                    <i class="bi bi-file-earmark"></i>
                    <span>Blank</span>
                </a>
            </li> -->
      <!-- End Blank Page Nav -->
    </ul>
  </aside>
  <!-- End Sidebar-->
</template>

<script setup>
import { useRouter } from "vue-router";
import { useUserStore } from "../../stores/user.js";

const user = useUserStore();
const router = useRouter();

const grafik = () => {
  router.push("/");
};
const lowongan = () => {
  router.push("/lowongan");
};
const dataUser = () => {
  router.push("/data-user");
};
const tambahLowongan = () => {
  router.push("/addlowongan");
};
const file = () => {
  router.push("/masterfile");
};
const seleksi = () => {
  router.push("/masterseleksi");
};
const tipePekerjaan = () => {
  router.push("/mastertipepekerjaan");
};
const tambahAdmin = () => {
  router.push("/add-admin");
};
const listPelamar = () => {
  router.push("/list-pelamar");
};
</script>
